<template>
  <div class="dropzone-container">
    <input
      type="file"
      multiple
      name="file"
      id="fileInput"
      class="hidden-input"
      @change="onChange"
      ref="imageUploader"
      accept=".pdf,.jpg,.jpeg,.png"
    />

    <label
      for="fileInput"
      @dragenter="dragover"
      @dragleave="dragleave"
      @drop="drop"
      class="file-label"
    >
      <div v-if="isDragging" class="drag-label">
        Release to drop files here.
      </div>
      <!-- <div v-else-if="!files.length">Drop files here or click here to upload.</div> -->
      <div v-else-if="!files.length">Click here to upload.</div>
    </label>

    <div class="preview-container mt-4" v-if="files.length">
      <div v-for="(file, index) in files" :key="index" class="preview-card">
        <div>
          <img class="preview-img" :src="generateThumbnail(file)" />
          <p :title="file.name" v-if="namingImage">
            {{ makeName(file.name) }}
          </p>
        </div>
        <button
          class="button-close ml-2"
          type="button"
          @click="remove(file, index)"
          title="Remove file"
          v-if="files.length > 1"
        >
          <span class="times">&times;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    namingImage: {
      default: false,
      type: Boolean,
    },
    listFile: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      this.files = [...this.files, ...this.$refs.imageUploader.files];
      this.$emit("imagesChange", this.files);
    },
    generateThumbnail(file) {
      if (file.name.substring(0, 4) === "http") return file.name;
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    makeName(name) {
      return (
        name.split(".")[0].substring(0, 3) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },
    async remove(file, i) {
      await this.$emit("imagesRemove", file, i);
      // if (response) this.files.splice(i, 1);
      // else return
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      const tempData = [];
      e.dataTransfer.files.forEach((file) => {
        const idxDot = file.name.lastIndexOf(".") + 1;
        const extFile = file.name
          .substr(idxDot, file.name.length)
          .toLowerCase();
        if (["jpg", "jpeg", "png"].includes(extFile)) {
          tempData.push(file);
        } else {
          this.$bvToast.toast(
            `filename ${file.name}, Tidak bisa menerima format ini`,
            {
              title: "Error",
              variant: "danger",
              solid: true,
            }
          );
        }
      });

      this.$refs.imageUploader.files = tempData;

      this.onChange();
      this.isDragging = false;
    },
  },
  mounted() {
    this.files = this.listFile;
  },
  watch: {
    listFile(newValue) {
      this.files = newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.dropzone-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px dashed #4b4b4b;
  position: relative;
  border-radius: 4px;
  min-height: 150px;
  z-index: 50;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.drag-label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #a2a2a2;
  opacity: 0.7;
  color: #fff;
  display: flex;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.preview-card {
  display: flex;
  padding: 5px;
  margin-left: 5px;
  position: relative;
  width: 152px;
  height: 152px;
  z-index: 15;
  position: relative;
}
.preview-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
  object-fit: scale-down;
}
.button-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: 23px;
  width: 20px;
  height: 20px;
  background: #eb5757;
  color: #fff;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  .times {
    line-height: 0;
    position: absolute;
    top: 8px;
  }
}
</style>
